import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Loader from './components/utility/loader.style';

const App = lazy(() => import('./app'));
const rootElement = document.getElementById('root');

render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
